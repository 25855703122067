body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(252, 252, 252);
  color: rgb(35, 35, 35);
}

a { 
  color: inherit; 
} 

thead {
  background-color: rgb(255, 153, 0);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.down-arrow { 
  width: 0;  
  height: 0;  
  border-left: 10px solid transparent; 
  border-right: 10px solid transparent; 
  border-top: 10px solid rgb(251, 103, 4); 
} 

.btn-primary {
  background-color: rgb(255, 153, 0);
  border-color: rgb(255, 153, 0);
}
.btn-primary:hover {
  background-color: rgb(251, 103, 4);
  border-color: rgb(240, 50, 50);
   
}

.navbar-nav {
  .nav-link {
    &.active,
    &.show {
      color: rgb(255, 153, 0);
    }
  }
}

#brand {
  color: rgb(255, 255, 255);
  font-family: -apple-system;
}
/* Use this for decorating element ./Brand.js 
@media only screen and (max-width: 800px) {
  #brand {
    flex: 0 0 70%;
  }
}
*/
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
}

input[disabled].form-control {
  color: darkgray;
}
select[disabled].form-select {
  color: darkgray;
}
textarea[disabled].form-control {
  color: darkgray;
}

select[multiple] option:checked {
  background-color: rgb(241, 167, 56);
  color:black;
}
select[multiple]:focus option:checked {
  background-color: rgb(241, 167, 56);
  color:black;
  font-weight: bold;
}

.imageLink {
  background-color: rgb(50, 50, 50);
  color: white;
}

.post {
  background-color: rgb(255, 153, 3);
  /*border-color: rgb(255, 72, 0);*/
  box-shadow: 3px 3px rgb(152, 152, 152);
}
.post:hover {
  background-color: rgb(251, 103, 4);
  /*border-color: rgb(255, 72, 0);*/
  box-shadow: 3px 3px rgb(152, 152, 152);
}

.post[selected] {
  background-color: rgb(251, 103, 4);
  /*border-color: rgb(255, 72, 0);*/
  box-shadow: 3px 3px rgb(152, 152, 152);
}

.post .card-header {
  background-color: rgb(50, 50, 50);
  border-color: rgb(255, 153, 3);
  color: rgb(219, 219, 219);
}
.post .card-footer {
  background-color: rgb(50, 50, 50);
  border-color: rgb(255, 153, 3);
  color: rgb(219, 219, 219);
}

.post-detail {
  background-color: rgb(245, 245, 245);
  border-color: rgb(255, 153, 0);
  border-style: outset;
}

.alert {
  border-color: rgb(255, 153, 0);
}
.alert-body {
  background-color: rgb(50, 50, 50);
  color: rgb(255, 153, 0);
}
.alert-icon {
  background-color: rgb(255, 153, 3);
}
.alert-detail {
  background-color: rgb(58, 57, 57);
  color: rgb(219, 219, 219);
}

#user-menu.tooltip .tooltip-inner  {
  max-width: 100% !important;
}

label {
  color: rgb(255, 153, 0);
  font-weight: bold;
}

.progress-bar {
  color: black;
  background-color: rgb(255, 153, 3);
  
}

/* Google map autocomplete drop down class */
.pac-container{
  z-index: 2000;
}








